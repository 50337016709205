// eslint-disable-next-line import/no-unassigned-import
import 'utils/polyfills.js'

import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { render } from 'react-dom'

import { routes } from 'const/routes'

import { App } from './App'
import { lazyRetry } from './utils/lazy-retry'

render(
  <BrowserRouter>
    <App>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            exact
            path={routes.landing}
            component={lazy(() => lazyRetry(() => import('pages/landing'), 'landing'))}
          />

          <Route
            exact
            path={routes.login}
            component={lazy(() => lazyRetry(() => import('pages/login'), 'login'))}
          />

          <Route
            exact
            path={routes.emailConfirmation}
            component={lazy(() =>
              lazyRetry(() => import('pages/email-confirmation'), 'emailConfirmation'),
            )}
          />

          <Route
            exact
            path={routes.registerExtraPage}
            component={lazy(() =>
              lazyRetry(() => import('pages/email-confirmation/extra-page'), 'registerExtraPage'),
            )}
          />

          <Route
            exact
            path={routes.registerByType}
            component={lazy(() => lazyRetry(() => import('pages/register'), 'registerBytType'))}
          />

          <Route
            exact
            path={routes.survey}
            component={lazy(() => lazyRetry(() => import('pages/register/survey'), 'survey'))}
          />

          <Route
            exact
            path={routes.registerThankYouPage}
            component={lazy(() =>
              lazyRetry(() => import('pages/register/thank-you-page'), 'registerThankYouPage'),
            )}
          />

          <Route
            exact
            path={routes.jointSign}
            component={lazy(() => lazyRetry(() => import('pages/joint-sign'), 'jointSign'))}
          />

          <Route
            exact
            path={routes.associatedSign}
            component={lazy(() =>
              lazyRetry(() => import('pages/associated-sign'), 'associatedSign'),
            )}
          />

          <Route
            exact
            path={routes.issuerSign}
            component={lazy(() => lazyRetry(() => import('pages/issuer-sign'), 'issuerdSign'))}
          />

          <Route
            exact
            path={routes.customSign}
            component={lazy(() => lazyRetry(() => import('pages/custom-sign'), 'customSign'))}
          />

          <Route
            path={routes.dashboard}
            component={lazy(() => lazyRetry(() => import('pages/dasboard'), 'dashboard'))}
          />

          <Route
            path={routes.account}
            component={lazy(() => lazyRetry(() => import('pages/user'), 'account'))}
          />

          <Route
            path={routes.accountLicensee}
            component={lazy(() =>
              lazyRetry(() => import('pages/user-licensee'), 'accountLicensee'),
            )}
          />

          <Route
            path={routes.accountInvestor}
            component={lazy(() =>
              lazyRetry(() => import('pages/user-investor'), 'accountInvestor'),
            )}
          />

          <Route
            path={routes.accountAdvisor}
            component={lazy(() => lazyRetry(() => import('pages/user-advisor'), 'accountAdvisor'))}
          />

          <Route
            path={routes.accountantAccount}
            component={lazy(() =>
              lazyRetry(() => import('pages/user-accountant'), 'accountantAccount'),
            )}
          />

          {/*          <Route
            path={routes.supervisorAccount}
            component={lazy(() =>
              lazyRetry(() => import('pages/user-supervisor'), 'supervisorAccount'),
            )}
          />*/}

          <Route
            exact
            path={routes.recoveryPassword}
            component={lazy(() =>
              lazyRetry(() => import('pages/password-recovery'), 'recoveryPassword'),
            )}
          />

          <Route
            exact
            path={routes.recoveryPasswordDone}
            component={lazy(() =>
              lazyRetry(() => import('pages/password-recovery/done'), 'recoveryPasswordDone'),
            )}
          />

          <Route
            path={routes.passwordReset}
            component={lazy(() => lazyRetry(() => import('pages/password-reset'), 'passwordReset'))}
          />

          <Route
            path={routes.clientConfirm}
            component={lazy(() => lazyRetry(() => import('pages/client-confirm'), 'clientConfirm'))}
          />

          <Route
            path={routes.userInvite}
            component={lazy(() => lazyRetry(() => import('pages/user-invite'), 'userInvite'))}
          />

          <Route
            path={routes.offerings}
            exact
            component={lazy(() => lazyRetry(() => import('pages/offerings'), 'offerings'))}
          />

          <Route
            path={routes.offeringsClosed}
            exact
            component={lazy(() =>
              lazyRetry(() => import('pages/offerings/offerings-closed-list'), 'offeringsClosed'),
            )}
          />

          <Route
            path={routes.offeringsCurrent}
            exact
            component={lazy(() =>
              lazyRetry(() => import('pages/offerings/offerings-current'), 'offeringsCurrent'),
            )}
          />

          <Route
            path={routes.offering}
            exact
            component={lazy(() =>
              lazyRetry(() => import('pages/offerings/offering-details'), 'offering'),
            )}
          />

          <Route
            path={routes.offeringDueDiligence}
            exact
            component={lazy(() =>
              lazyRetry(
                () => import('pages/offerings/offering-due-diligence'),
                'offeringDueDiligence',
              ),
            )}
          />
          <Route
            path={routes.offeringAdvisorSnapshot}
            exact
            component={lazy(() =>
              lazyRetry(
                () => import('pages/offerings/offering-advisor-snapshot'),
                'offeringAdvisorSnapshot',
              ),
            )}
          />

          <Route
            path={routes.offeringDueDiligenceTest}
            exact
            component={lazy(() =>
              lazyRetry(
                () => import('pages/offerings/offering-due-diligence-test'),
                'offeringDueDiligenceTest',
              ),
            )}
          />

          <Route
            path={routes.offeringSecondPage}
            exact
            component={lazy(() =>
              lazyRetry(() => import('pages/offerings/offering-second-page'), 'offeringSecondPage'),
            )}
          />

          <Route
            path={routes.offeringThirdPage}
            exact
            component={lazy(() =>
              lazyRetry(() => import('pages/offerings/offering-third-page'), 'offeringThirdPage'),
            )}
          />

          <Route
            path={routes.offeringOnboarding}
            component={lazy(() =>
              lazyRetry(() => import('pages/offering-onboarding'), 'offeringOnboarding'),
            )}
          />

          <Route
            path={routes.addInvestment}
            component={lazy(() => lazyRetry(() => import('pages/invest'), 'addInvestment'))}
          />

          <Route
            exact
            path={routes.errorPage}
            component={lazy(() => lazyRetry(() => import('pages/error'), 'errorPage'))}
          />

          <Route
            exact
            path={routes.contactUs}
            component={lazy(() => lazyRetry(() => import('pages/contact-us'), 'contactUs'))}
          />

          <Route
            exact
            path={routes.redirectAfterSignIn}
            component={lazy(() =>
              lazyRetry(() => import('pages/redirect-after-sing-in'), 'redirectAfterSignIn'),
            )}
          />

          <Route
            path="/*"
            component={lazy(() => lazyRetry(() => import('pages/custom-pages'), 'custom-pages'))}
          />
        </Switch>
      </Suspense>
    </App>
  </BrowserRouter>,
  document.getElementById('root'),
)
